<div *ngIf="loading">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade">
        <p style="font-size: 20px; color: white;">
            Loading
            <span aria-hidden="true">...</span>
        </p>
    </ngx-spinner>
</div>
<div *ngIf="isModeOfReceiptSelectNeeded" class="row justify-content-center py-5">
    <div class="col-md-6">
        <label for="app-mode-of-receipt" class="control-label"> <span class="required-asterisk" aria-label="required information">*</span>Please identify how you were contacted to help this customer: </label>
        <select id="app-mode-of-receipt" class="form-select form-select-md" name="app-mode-of-receipt" required (change)="onModeOfReceiptSelect($event)">
            <option value>-- Please Select --</option>
            <option value="F">Fax</option>
            <option value="I">In Person</option>
            <option value="M">Mail</option>
            <option value="T">Telephone</option>
            <option value="V">VRS</option>
        </select>
    </div>
</div>
<div *ngIf="!isOscSessionIdValid || !isTransactionValid" class="container">
    <div class="row justify-content-center py-5">
        <div class="col-md-6">
            <label for="app-invalid-transaction" class="control-label">
                Sorry, but we cannot complete your payment because too much time has passed or there was another technical issue. Please return to the site where you were attempting to place your order.
            </label>
        </div>
    </div>
</div>
<div *ngIf="isShowPage()">
    <div class="container-fluid mt-4 mb-4 app__width">
        <div class="row">
            <div class="col-12">
                <fa-icon [icon]="faLeft" class="back_icon__decor"></fa-icon>
                <a class="link" href="#" (click)="backToOrder(); $event.preventDefault()" style="color: #797979; font-size: 16px; font-family: Arial, Helvetica, sans-serif; margin-left: 4px;">
                    <span class="app_back_to_app">BACK</span>
                </a>
            </div>
        </div>
        <div class="row mb-4 space_align_abandoned_desktop">
            <div class="col-12"></div>
        </div>
        <div class="row mb-2 space_align_abandoned_mobile">
            <div class="col-12"></div>
        </div>
        <app-secure-checkout></app-secure-checkout>
        <div class="row large_screen">
            <div class="col-lg-8">
                <app-order [orderData]="orderDetails" [paymentType]="paymentType" [contact]="contact" [paymentStatus]="paymentStatus" [orderData]="orderDetails"></app-order>
                <!-- <app-checkout [orderData]="orderDetails"></app-checkout><div class="select-payment-title"><app-order
                        [paymentType]="paymentType"
                        [contact]="contact"
                        [paymentStatus]="paymentStatus"
                        [orderData]="orderDetails"
                      ></app-order></div> -->
            </div>
            <div class="col-lg-4 mb-4 app_order_checkout_info">
                <app-checkout [orderData]="orderDetails"></app-checkout>
                <app-order-info [orderData]="orderDetails"></app-order-info>
            </div>
        </div>
        <div class="row small_screen__order_info">
            <app-checkout [orderData]="orderDetails"></app-checkout>
            <app-order-info [orderData]="orderDetails"></app-order-info>
            <div class="col-mb-12 app_order_checkout_info">
                <app-order [orderData]="orderDetails" [paymentType]="paymentType" [contact]="contact" [paymentStatus]="paymentStatus" [orderData]="orderDetails"></app-order>
                <!-- <app-checkout [orderData]="orderDetails"></app-checkout><div class="select-payment-title"><app-order
                  [paymentType]="paymentType"
                  [contact]="contact"
                  [paymentStatus]="paymentStatus"
                  [orderData]="orderDetails"
                ></app-order></div> -->
            </div>
        </div>
    </div>
</div>

