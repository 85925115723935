<header class="ets-header">
  <div class="navbar navbar-dark navbar-expanded ets-main-banner">
      <div class="container-fluid">
          <div></div>
          <div class="navbar-brand d-flex align-items-center">
              <img class="navbar-logo" src="../../assets/img/ETS_header_logo.svg" alt="E T S Logo" />
          </div>
          <div></div>
      </div>
  </div>
</header>
