<footer class="footer-align">
    <div class="container-fluid footer-text-align">
        <ul class="align__hrefs_toefl">
          <li><a id="#footer" href="https://www.ets.org/legal" target="_blank"><span class="line__font_text">Legal</span></a></li>
          <li><a href="https://www.ets.org/legal/privacy" target="_blank"><span class="line__font_text">Privacy and Security</span></a></li>
          <li><a href="https://www.ets.org/legal/trademarks/owned" target="_blank"><span class="line__font_text">ETS Trademarks</span></a></li>
        </ul>
      <p>Copyright © 2024 by Educational Testing Service. All rights reserved. All trademarks are the property of their respective owners.</p>
    </div>
</footer>
