<div *ngIf="loading === true">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade">
        <p style="font-size: 20px; color: white;">Loading <span aria-hidden="true">...</span></p>
    </ngx-spinner>
</div>
<!-- payment redirect form -->
<app-merchant-response></app-merchant-response>
<div class="payment-details" *ngIf="loading === false">
    <h6 class="pdetails_title">Payment Details</h6>
    <h6 class="pdetails_title_sub">Select a payment method</h6>
    <div>
        <form (submit)="submitPymntDetail()">
            <section class="pymnt__section_cc">
                <div class="radio_inline__payments__cc" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
                    <input id="cc" type="radio" required value="cc" name="pymntdetails" [(ngModel)]="selectPymntType" />
                    <div class="label__inline_responsive">
                        <label class="radio-inline inline__cc" for="cc">Credit Card</label>
                        <div class="cc_payment_types">
                            <img src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="amex" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="diners" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsamex-copy.png" alt="discover" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="jcb" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="mastercard" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="paypal" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="rupay" class="pymnt_cc_amex_logo" />
                            <img src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="union" class="pymnt_cc_amex_logo_rupay" />
                        </div>
                    </div>
                </div>
                <div *ngIf="selectPymntType === 'cc'" class="pymnt_details__cc_pymnt">
                    <h1 class="pymnt_cc__header">Credit Card Information</h1>
                    <app-credit-card [billingDetails]="billingDetails" [status]="status" [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()" [orderData]="orderData"></app-credit-card>
                </div>
            </section>
            <div class="radio_inline__payments_pp" *ngIf="orderData && isPaypalEnabled()">
                <section class="pymnt__section_pp" *ngIf="!gd?.isStubEnabled">
                    <input id="pp" type="radio" required value="pp" name="pymntdetails" [(ngModel)]="selectPymntType" />
                    <label for="pp" *ngIf="!gd?.isStubEnabled">
                        <div data-pp-message data-pp-style-text-size="12" data-pp-style-text-align="left"></div>
                    </label>
                </section>
                <section class="pymnt__section_pp" *ngIf="gd?.isStubEnabled">
                    <div class="pp_stub_text">
                        <input id="pp" type="radio" required value="pp" name="pymntdetails" [(ngModel)]="selectPymntType" />
                        <label for="pp">
                            <span class="inline__cc">Paypal</span>
                        </label>
                    </div>
                </section>
                <div id="payPal" *ngIf="selectPymntType === 'pp' && !gd?.isStubEnabled">
                    <app-paypal></app-paypal>
                </div>
                <!-- rendered paypal stub section -->
                <div id="payPal" *ngIf="selectPymntType === 'pp' && gd?.isStubEnabled" aria-hidden="true">
                    <app-paypal-stub></app-paypal-stub>
                </div>
            </div>
            <section class="pymnt__section_alp" *ngIf="isAlipayEnabled()">
                <div class="radio_inline__payments_alp">
                    <input id="alp" type="radio" required value="alp" name="pymntdetails" [(ngModel)]="selectPymntType" />
                    <div class="label__inline_responsive">
                        <label for="alp">
                            <div class="alipay-helptext">
                                <img *ngIf="!activeAP" class="alipay alipay-helptext-logo" src="../../assets/img/AlipayPlus.png" alt="Alipay+" />
                                <img *ngIf="activeAP" class="alipay-white alipay-helptext-logo" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
                                <img *ngIf="!activeAP" class="alipay-white alipay-hover alipay-helptext-logo" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
                            </div>
                        </label>
                        <div class="alipay_text__description">
                            Pay in your local currency using local e-wallets popular in many countries in Asia. 
                            <a class="learn-more" (click)="openDialog()"> Learn more</a>
                        </div>
                    </div>
                </div>
                <div id="alipay" *ngIf="selectPymntType === 'alp'" class="pymnt_details__cc_pymnt">
                    <app-alipay [alipayStatus]="alipayStatus" [orderData]="orderData"></app-alipay>
                </div>
            </section>
        </form>
    </div>
</div>
<app-accepted-payment-types *ngIf="loading === false" [paypalEnabled]="paypalEnabled" [alipayEnabled]="alipayEnabled" [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()"></app-accepted-payment-types>
