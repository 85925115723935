import { Component } from '@angular/core';

@Component({
  selector: 'app-cvvtooltipcard',
  templateUrl: './cvvtooltipcard.component.html',
  styleUrl: './cvvtooltipcard.component.css'
})
export class CvvtooltipcardComponent {
  
}
