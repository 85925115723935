import { Component, Input, ViewChild } from '@angular/core';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { Transaction } from '../models/transaction';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent {
  @Input() orderData: Transaction;
  @ViewChild(MerchantResponseComponent) public merchantResponseComponent;
  faDown = faArrowDown;
  faUp = faArrowUp;
  expanddetails: boolean = false;
  collapsable_line__items_condition: string = ' collapsable_line__items';

  constructor() {
    console.log('[ order data ]', this.orderData);
  }

  modifyYourOrder() {
    this.merchantResponseComponent.backToOrder('backToOrder', '', {});
  }

  expandDetails(val: boolean) {
    this.expanddetails = val;
    this.collapsable_line__items_condition = '';
  }

  collapseDetails(val: boolean) {
    this.collapsable_line__items_condition += ' collapsable_line__items';
    this.expanddetails = val;
  }
}
