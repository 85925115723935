<div
  id="shipping-contact-info"
  class="card rounded-card receiving-information"
  *ngIf="gd?.oscConfig?.displayShippingFlds"
  tabindex="0"
  role="document"
>
  <div class="card-body shpngcontct {{shpngcontct_mobile}}">
    <div class="order_info__shpngcontct">
      <h6 class="shipping__title_infor">Shipping/Contact</h6>
      <div class="expand__shipping_info {{caret_expand_lrgscrn}} {{caret_expand_lrgscrn_collapse}}" (click)="expandShippingInfo()"><span class="shipping__title_infor_expand">Expand Info</span><img src="../../assets/svg/caret_down.svg" alt="down"/></div>
    </div>
    <div class="mt-2 shipping__text_font_types {{caret_expand}}">
      <span
        >This is the name and address of the person who will receive the
        product/service (e.g., the person who will be taking a test or is
        requesting scores); or, if you ordered items to be shipped, they will be
        sent to this address.</span
      >
    </div>
    <div class="shipping__text_address shipping__text_address_details {{caret_expand}}">
      <span *ngIf="orderData?.shippingAddress?.firstName">{{
        orderData?.shippingAddress?.firstName
      }}</span
      >&nbsp;

      <span *ngIf="orderData?.shippingAddress?.lastName">{{
        orderData?.shippingAddress?.lastName
      }}</span>
    </div>
    <div class="shipping__text_address_details">
      <div
        *ngIf="orderData?.shippingAddress?.company"
        class="shipping__text_address {{caret_expand}}" 
      >
        {{ orderData?.shippingAddress?.company }}
      </div>
      <div class="shipping__text_address {{caret_expand}}">
        {{ orderData?.shippingAddress?.addressLine1 }}
      </div>
      <div class="shipping__text_address {{caret_expand}}">
        {{ orderData?.shippingAddress?.addressLine2 }}
      </div>
      <div class="address_multi_group {{caret_expand}}">
        <div class="shipping__text_address {{caret_expand}}">
          {{ orderData?.shippingAddress?.city }}
          {{ orderData?.shippingAddress.region }}
        </div>
        <div class="shipping__text_address">
          {{ orderData?.shippingAddress?.postalCode }}
        </div>
        <div class="shipping__text_address">
          {{ orderData?.shippingAddress?.country }}
        </div>
      </div>
    </div>
    <div
      class="mt-2 {{caret_expand}}"
      *ngIf="orderData?.shippingContact?.phoneNumber"
    >
      <span aria-label="phone number" class="shipping__text_address_small">
        <!-- <fa-icon [icon]="faPhone"></fa-icon> -->
        Phone:
      </span>
      <span class="shipping__text_address">{{ orderData?.shippingContact?.phoneNumber }}</span>
    </div>
    <div
      *ngIf="orderData?.shippingContact?.email"
      class="{{caret_expand}}"
    >
      <span aria-label="email address" class="shipping__text_address_small">
        <!-- <fa-icon [icon]="faEnvelope"></fa-icon> -->
         Email: 
      </span>
      <span class="shipping__text_address">{{ orderData?.shippingContact?.email }}</span>
    </div>
  </div>
  <div class="collapse_section_shpngInfo {{caret_expand}}">
    <div class="expand__shipping_info {{caret_expand_lrgscrn}}" (click)="collapseShippingInfo()"><span class="shipping__title_infor_expand">Collapse Info</span><img src="../../assets/svg/caret_up.svg" alt="up"/></div>
  </div>
</div>
