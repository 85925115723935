<div class="alert alert-danger" role="alert" *ngIf="alipayStatus && alipayStatus !== 'approved'">
    Your Alipay transaction was declined. Try again using a different payment method.
</div>

<div class="card-body">
    <app-merchant-response></app-merchant-response>
    <form [formGroup]="billingInformation">
        <h1 class="pb-2 pymnt_cc__header">Billing Information</h1>
        <ng-container *ngIf=" gd?.oscConfig?.displayBillingName">
            <app-billing-name-information formGroupName="nameDetails" [orderData]="orderData"></app-billing-name-information>
        </ng-container>
        <ng-container *ngIf="gd?.oscConfig?.displayBillingAddress">
            <app-billing-address-information formGroupName="addressDetails" [orderData]="orderData"></app-billing-address-information>
        </ng-container>
    </form>
    <div class="col-lg-12">
        <div class="col-lg-12 pt-2 sub-text alipay-font">
            <div class="lock">
                <fa-icon [icon]="faLock"></fa-icon>
            </div>
            ETS processes your payment with your security in mind. This may impact your experience.
        </div>
        <div class="col-lg-12">
            <button id="submit" [ngClass]="billingInformation.invalid ? 'disabled-bkg-alipay' : 'btn--primary-alipay'" class="processing-button" [disabled]="billingInformation?.invalid" (click)="createAlipayPayment()">
                <fa-icon [icon]="faLock"></fa-icon>
                <span class="cc_submit">Process Payment (${{orderData?.amount | number : '1.2-2'}} <span>USD</span>)</span>
            </button>
        </div>
    </div>
</div>
