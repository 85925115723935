import { Component } from '@angular/core';
import {  faLock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-secure-checkout',
  templateUrl: './secure-checkout.component.html',
  styleUrls: ['./secure-checkout.component.css']
})
export class SecureCheckoutComponent {
  faLock = faLock;
}
