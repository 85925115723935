<form [formGroup]="form">
  <div class="row">
     <div class="col-lg-12 pb-3 alipay__check_label" *ngIf="gd?.oscConfig?.displayShpngAsBillngNam">
        <input type="checkbox" id="sameAsShippingContactName" name="sameAsShippingContactName"
           formControlName="sameAsShippingContactName" (change)="sameNamecheckbox($event)" />
        <label class="ps-2 address_label" for="sameAsShippingContactName">Same as Shipping/Contact name</label>
     </div>
     <div class="alipay__name_info">
        <div class="col-lg-12 col-sm-12 col-md-12 pb-3">
          <label for="firstName"><span class="bilng_adrs_details__label">First Name</span><span class="required-asterisk">*</span></label>
          <input id="firstName" name="firstName" class="form-control form-control-md" formControlName="firstName"
            placeholder="First name" />
          <span *ngIf="
            (form.controls['firstName'].hasError('required') || form.controls['firstName'].hasError('minLength')) &&
            form.controls['firstName'].touched
            " class="text-danger">
          You must enter First name.</span>
      </div>
      <div class="col-lg-12 col-sm-12 pb-3">
          <label for="lastName"><span class="bilng_adrs_details__label">Last Name</span><span class="required-asterisk">*</span></label>
          <input id="lastName" name="lastName" class="form-control form-control-md" formControlName="lastName"
            placeholder="Last name" />
          <span *ngIf="
            (form.controls['lastName'].hasError('required') || form.controls['lastName'].hasError('minLength')) &&
            form.controls['lastName'].touched
            " class="text-danger">
          You must enter Last name.</span>
      </div>
     </div>
  </div>
</form>
