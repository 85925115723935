<!-- <div tabindex="0" aria-hidden="true" role="tooltip" class="btn btn-tooltip "  id="cvv-tip" ngbTooltip="Tooltip on top"
ngbTooltip="Card Verification Value is a three- or four-digit number printed on the back or front of credit cards."
tooltipClass="my-custom-class">
<fa-icon class="question" [icon]="faQuestionCircle"></fa-icon>
</div> -->


<div class="tooltiptext">
    <img src="../../assets/svg/cvvtip.svg"/>
    <span class="tooltiptextcvv"><app-cvvtooltipcard></app-cvvtooltipcard></span>
</div>
